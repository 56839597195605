<template>
<div>
    <span v-if="answer !== 'null' || answer !== null">{{ answer }}</span>
    <span v-else>Incomplete</span>
</div>
</template>
<script>
export default {
    
    props: {
        answer: {
            type: [String, Number]
        },
        builder: {
            type: Object,
            default: () => ({})
        }
    },

}
</script>